<template>
  <b-container>
    <v-select
        class="w-50 mr-auto ml-auto mt-3"
        placeholder="адрес"
        :filterable="false"
        v-model="address"
        :options="addressSuggestions"
        @search="onAddressSearch"
    >
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          <p class="mb-0">Ничего не нашли</p>
        </template>
        <p v-else class="mb-0 text-secondary font-weight-light">Начните вводить адрес...</p>
      </template>
      <template v-slot:option="option">
        {{ option.value }}
      </template>
      <template v-slot:selected-option="option">
        {{ option.value }}
      </template>
    </v-select>
    <p>Стоимость доставки: <span v-if="price !== null">{{ price }}</span></p>
  </b-container>
</template>

<script>
import * as debounce from "lodash.debounce";

export default {
  name: "OrderForm",
  data() {
    return {
      address: null,
      addressSuggestions: [],
      price: null
    }
  },

  watch: {
    address: async function(newVal, oldVal) {
      this.$api.get("delivery/check_price", {lon: this.address.lon, lat: this.address.lat}).then(res => {
        this.price = res
      })
    }
  },

  methods: {
    onAddressSearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.addressSearch(loading, search, this)
      }
    },

    addressSearch: debounce((loading, search, vm) => {
      vm.$api.get("delivery/search_address", {query: search}).then(res => {
        vm.addressSuggestions = res
        loading(false)
      })
    }, 350),
  }

}
</script>

<style scoped>

</style>