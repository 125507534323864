<template>
  <b-modal id="add-customer" v-model="global.addCustomerVisible"
           :title="isNew ? 'Добавить клиента' : 'Редактировать клиента'"
           @show="onShow" @ok="onSubmit">
    <b-form @submit="onSubmit">
      <b-form-group>
        <vue-simple-suggest
            type="text"
            placeholder="Имя"
            v-model="customer.name"
            :debounce="250"
            autofocus
            :state="validateName"
            :list="suggestionList"
            :filter-by-query="true"
        >
        </vue-simple-suggest>
        <b-form-invalid-feedback :state="validateName">
          Введите имя
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-input
            type="text"
            placeholder="Фамилия"
            v-model="customer.lastName"
            debounce="250">
        </b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-textarea
            placeholder="Комментарий"
            v-model="customer.comment"
            rows="4"
            max-rows="6"
            debounce="250">
        </b-form-textarea>
      </b-form-group>

      <b-form-group
          label="Тип контакта"
          label-cols-sm="4">
        <b-form-radio-group
            v-model="customer.contactType"
            :options="contactTypesArr"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
          label="Пол"
          label-cols-sm="4">
        <b-form-radio-group
            v-model="customer.type"
            :options="customerTypesArr"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group>
        <b-form-input
            type="text"
            placeholder="Контакт"
            v-model="customer.contact"
            debounce="250"
            :state="validateContact">
        </b-form-input>
        <b-form-invalid-feedback :state="validateContact">
          {{ serverValidationError == null ? validateContactError : serverValidationError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
          label="Скидка %"
          label-cols-sm="4">
        <b-form-input
            type="number"
            placeholder="Скидка %"
            v-model="customer.discount"
            debounce="250"
            :state="validateDiscount">
        </b-form-input>
        <b-form-invalid-feedback :state="validateDiscount">
          Скидка должна быть от 0 до 50%
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
          label="Бонус"
          label-cols-sm="4">
        <b-form-input
            type="number"
            placeholder="Накопленный бонус"
            v-model="customer.bonus"
            :disabled="!user.admin"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
          label="Временный бонус"
          label-cols-sm="4">
        <b-input-group>
          <b-form-input
              type="number"
              v-model="customer.temporaryBonus"
              disabled>
          </b-form-input>
          <b-input-group-append>
            <b-input-group-text v-if="customer.temporaryBonusDeadlineStr">
              сгорят {{ customer.temporaryBonusDeadlineStr }}
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="customer.sendMailings" class="mb-2 ml-1">
          Слать рассылки
        </b-form-checkbox>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>

import {isBlank, isPhone} from "@/utils";
import {store} from "@/store"
import VueSimpleSuggest from 'vue-simple-suggest/lib'
import {consts} from "@/consts";

export default {
  components: {
    VueSimpleSuggest
  },

  data() {
    return {
      submitted: false,
      serverValidationError: null,
      validateContactError: "",
      customer: {},
      contactTypesArr: consts.customers.contactTypesArr,
      customerTypesArr: consts.customers.customerTypesArr,
      global: store.customers,
      user: store.user,
    }
  },

  computed: {
    validateName() {
      return this.submitted ? !isBlank(this.customer.name) : null
    },
    validateDiscount() {
      return this.submitted ? (this.customer.discount >= 0 && this.customer.discount <= 50) : null
    },
    validateContact() {
      if (this.submitted) {
        if (this.serverValidationError) {
          return false
        }
        switch (this.customer.contactType) {
          case 1:
          case 2:
            this.validateContactError = "Введите номер телефона в формате +7(123)456-7890"
            return isPhone(this.customer.contact)

          case 3:
          case 4:
          case 5:
            this.validateContactError = "Введите ссылку на профиль"
            return !isBlank(this.customer.contact)
        }
      } else {
        return null
      }
    },
    isNew() {
      return this.customer == null || this.customer.id == null
    }
  },
  methods: {
    suggestionList() {
      return this.$api.get("names", {prefix: this.customer.name})
    },
    onShow() {
      this.customer = Object.assign({}, this.global.selectedCustomer)
      if (this.customer.name == null) this.customer.name = ""
      if (this.customer.discount == null) this.customer.discount = 0
      if (this.customer.contactType == null) this.customer.contactType = 1
      if (this.customer.sendMailings === undefined) this.customer.sendMailings = true
      this.customer.temporaryBonusDeadlineStr = this.customer.temporaryBonusDeadline ? new Date(this.customer.temporaryBonusDeadline).toLocaleDateString() : null
      this.submitted = false
      this.serverValidationError = null
    },

    async onSubmit(event) {
      event.preventDefault()

      this.submitted = true
      this.serverValidationError = null

      if (this.validateName && this.validateDiscount && this.validateContact) {
        try {
          let newCustomer = await this.$api.post("customers", this.customer)
          this.global.onCustomerSaved(newCustomer)
          this.global.addCustomerVisible = false
          if (this.isNew) {
            this.$bvToast.toast(`${newCustomer.name} добавлен`, {
              title: 'Новый клиент',
              autoHideDelay: 5000,
              appendToast: true,
              solid: true
            })
          }
        } catch (e) {
          this.serverValidationError = e.toString()
        }
      }
    }
  }
}
</script>
