<template v-if="global.addTemporaryBonusVisible">
  <b-modal id="change-delivery" v-model="global.addTemporaryBonusVisible" title="Временные бонусы"
           @show="onShow" @ok="onSubmit">
    <b-form-group>
      <b-input-group prepend="количество бонусов" class="mt-1">
        <b-form-input
            type="number"
            v-model="bonus"
        ></b-form-input>
      </b-input-group>

      <b-input-group prepend="Дата сгорания" class="mt-1">
        <b-form-datepicker
            placeholder="Дата сгорания"
            today-button
            reset-button
            close-button
            start-weekday="1"
            :min="new Date()"
            v-model="deadline"
            :value-as-date="true">
        </b-form-datepicker>
      </b-input-group>

    </b-form-group>
  </b-modal>
</template>


<script>

import {store} from "@/store"

export default {

  data() {
    return {
      deadline: null,
      bonus: 0,
      global: store.customers,
    }
  },



  methods: {
    onShow() {
      if (this.selectedClaimId === null && store.orders.selectedOrder.deliveryClaimId !== null) {
        this.selectedClaimId = store.orders.selectedOrder.deliveryClaimId
      } else if (store.orders.selectedOrder.deliveryClaimId === null) {
        this.selectedClaimId = null;
      }
    },

    async submit() {
      await this.$api.postParams("customers/addTempBonus", {count: this.bonus, deadline: this.deadline})
    },

    async onSubmit(event) {
      event.preventDefault()
      await this.submit()
      try {
        this.global.onCustomerSaved()
        store.customers.addTemporaryBonusVisible = false
      } catch (e) {
      }
    }

  }
}
</script>
