<template>
  <b-modal id="add-customer" v-model="global.changeCustomerTypeVisible"
           :title="'Редактировать клиента'"
           @show="onShow" @ok="onSubmit">
    <h6>Укажите пол клиента</h6>
    <p>{{ customer.name }} {{ customer.lastName }}</p>
    <b-form-group
        label="Пол"
        label-cols-sm="4">
      <b-form-radio-group
          v-model="customer.type"
          :options="customerTypesArr"
          button-variant="outline-primary"
          size="sm"
          buttons
      ></b-form-radio-group>
    </b-form-group>
  </b-modal>
</template>

<script>
import {consts} from "@/consts";
import {store} from "@/store";

export default {
  data() {
    return {
      customer: {},
      contactTypesArr: consts.customers.contactTypesArr,
      customerTypesArr: consts.customers.customerTypesArr,
      global: store.customers
    }
  },
  methods: {
    onShow() {
      this.customer = Object.assign({}, this.global.selectedCustomer)
      if (this.customer.name == null) this.customer.name = ""
      if (this.customer.discount == null) this.customer.discount = 0
      if (this.customer.contactType == null) this.customer.contactType = 1
      if (this.customer.sendMailings === undefined) this.customer.sendMailings = true
      this.customer.temporaryBonusDeadlineStr = this.customer.temporaryBonusDeadline ? new Date(this.customer.temporaryBonusDeadline).toLocaleDateString() : null
    },
    async onSubmit(event) {
      event.preventDefault()


      try {
        let newCustomer = await this.$api.post("customers", this.customer)
        this.global.onCustomerSaved(newCustomer)
        this.global.changeCustomerTypeVisible = false
      } catch (e) {

      }
    }

  }
}
</script>