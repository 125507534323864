import { render, staticRenderFns } from "./OrderForm.vue?vue&type=template&id=1fc2216c&scoped=true"
import script from "./OrderForm.vue?vue&type=script&lang=js"
export * from "./OrderForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fc2216c",
  null
  
)

export default component.exports