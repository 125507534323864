<template>
  <b-modal id="add-order" size="md" v-model="global.addOrderWithFormVisible" scrollable
           :title="'Добавить заказ через форму'"
           @show="onShow" @ok="onSubmit">
    <b-form @submit="onSubmit">
      <SelectCustomer :customers="customers" :order="order" :server-validation-error="serverValidationError"
                      :validate-customer="validateCustomer"></SelectCustomer>
      <OrderItems :submitted="submitted" :order="order" :products="products" :product-search="productSearch"></OrderItems>
      <b-form-checkbox v-model="isHoliday">Праздничная форма</b-form-checkbox>
    </b-form>
  </b-modal>
</template>

<script>
import SelectCustomer from "@/views/SelectCustomer";
import OrderItems from "@/views/OrderItems";
import {store} from "@/store"

export default {
  name: "AddOrderWithForm",
  components: {OrderItems, SelectCustomer},
  props: {
    copyFormLink: Function,
    copyHolidayFormLink: Function,
    productSearch: Function
  },
  data() {
    return {
      submitted: false,
      serverValidationError: null,
      order: this.newOrder(),
      customers: [],
      products: [],
      florists: [],
      global: store.orders,
      isHoliday: false,

    }
  },
  computed: {
    validateCustomer() {
      return this.submitted ? (this.order.customer != null && this.serverValidationError == null) : null
    },
    total() {
      return this.order.items.reduce((sum, i) => sum + i.amount * i.price, 0)
    },
  },
  methods: {
    newOrder() {
      return {
        deliveryType: 1,
        deliveryIntervalType: 1,
        items: [{amount: 1, price: 0, product: {id: 1, name: "Свободная позиция"}}],
        paymentType: 1,
        customer: null,
        discount: 0,
        discountFixed: 0,
        deliveryCost: 0,
        tags: ""
      }
    },
    validateItems() {
      return this.order.items.every(i => i.product != null && i.amount > 0 && i.price >= 0)
    },
    onProductSearch(search, loading) {
      loading(true);
      this.productSearch(loading, search, this);
    },
    onShow() {
      // clone order for editing
      this.order = Object.assign({}, this.newOrder(), this.global.selectedOrder)
      // copy items for editing
      this.order.items = this.order.items.map(a => ({...a}))
      this.submitted = false
      this.serverValidationError = null

      this.$api.loadFlorists(res => {
        this.florists = res
      })
      this.onProductSearch("", () => {
      })
    },
    async onSubmit(event) {
      event.preventDefault()

      this.submitted = true
      this.serverValidationError = null

      if (this.validateCustomer && this.validateItems()) {
        try {
          this.order.total = this.total
          this.order.createBy = store.user;
          let newOrder = await this.$api.post("order", this.order)
          this.global.onOrderSaved(newOrder)

          if (!this.isHoliday) await this.copyFormLink(newOrder.id, this.order.customer.bonus);
          else await this.copyHolidayFormLink(newOrder.id, this.order.customer.bonus);

          this.global.addOrderWithFormVisible = false
          if (this.isNew) {
            this.$bvToast.toast(`${newOrder.id} добавлен`, {
              title: 'Новый заказ',
              autoHideDelay: 5000,
              appendToast: true,
              solid: true
            })
          }
        } catch (e) {
          this.serverValidationError = e.toString()
        }
      }
    },

  }
}
</script>

<style scoped>

</style>
