<template>
  <b-modal id="add-product" v-model="global.addProductVisible"
           :title="isNew ? 'Добавить товар' : 'Редактировать товар'"
           @show="onShow" @ok="onSubmit">
    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-input
            type="text"
            placeholder="Название товара"
            v-model="product.name"
            debounce="250"
            autofocus
            :state="validateName">
        </b-form-input>
        <b-form-invalid-feedback :state="validateName">
          {{ serverValidationError == null ? "Введите название товара" : serverValidationError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-textarea
            placeholder="Описание товара"
            v-model="product.description"
            rows="4"
            max-rows="6"
            debounce="250"
            :state="validateDescription">
        </b-form-textarea>
        <b-form-invalid-feedback :state="validateDescription">
          Введите описание товара
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
          label="Цена"
          label-cols-sm="4">
        <b-form-input
            type="number"
            placeholder="Цена"
            v-model="product.price"
            debounce="250"
            :state="validatePrice">
        </b-form-input>
        <b-form-invalid-feedback :state="validatePrice">
          Введите цену товара
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
          label="Старая цена"
          label-cols-sm="4">
        <b-form-input
            type="number"
            placeholder="Старая цена"
            v-model="product.oldPrice">
        </b-form-input>
      </b-form-group>

      <b-form-group
          label="Цена закупки"
          label-cols-sm="4">
        <b-form-input
            type="number"
            placeholder="Цена закупки"
            v-model="product.purchasePrice">
        </b-form-input>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>

import {isBlank} from "@/utils";
import {store} from "@/store"

export default {
  data() {
    return {
      submitted: false,
      serverValidationError: null,
      product: {},
      global: store.products
    }
  },

  computed: {
    validateName() {
      return this.submitted ? (!isBlank(this.product.name) && this.serverValidationError == null) : null
    },
    validateDescription() {
      return this.submitted ? !isBlank(this.product.description) : null
    },
    validatePrice() {
      return this.submitted ? !isBlank(this.product.price) : null
    },
    isNew() {
      return this.product == null || this.product.id == null
    }
  },

  methods: {
    onShow() {
      this.product = Object.assign({}, this.global.selectedProduct)
      this.submitted = false
      this.serverValidationError = null
    },

    async onSubmit(event) {
      event.preventDefault()

      this.submitted = true
      this.serverValidationError = null

      if (this.validateName && this.validateDescription && this.validatePrice) {
        try {
          let newProduct = await this.$api.post("products", this.product)
          this.$emit('saved', newProduct)
          this.global.addProductVisible = false
          if (this.isNew) {
            this.$bvToast.toast(`${newProduct.name} добавлен`, {
              title: 'Новый товар',
              autoHideDelay: 5000,
              appendToast: true,
              solid: true
            })
          }
        } catch (e) {
          this.serverValidationError = e.toString()
        }
      }
    }
  }
}
</script>
