<template>
  <b-modal id="view-customer" v-model="global.viewCustomerVisible"
           title="Информация о клиенте" @show="onShow" ok-only>
    <p>{{ customer.name }} {{ customer.lastName }}</p>
    <p>Пол: {{ types[customer.type] === undefined ? '???' : types[customer.type].text }}</p>
    <p>{{ customer.comment }}</p>
    <p>
      <CustomerContact :customer="customer"/>
    </p>
    <p>Скидка: {{ customer.discount }}%</p>
    <p>Накоплено бонусов: {{ customer.bonus }}</p>
    <p>Временных бонусов: {{ customer.temporaryBonus }}
      {{ customer.temporaryBonusDeadline ? 'до ' + customer.temporaryBonusDeadlineStr : '' }}</p>
    <p>Всего {{ customer.ordersCount }} заказ(ов) на сумму {{ customer.ordersTotalSum }}р</p>
    <p>Последний заказ {{ new Date(customer.ordersLastDeliveryDate).toLocaleDateString() }}</p>
    <p><a :href="'/orders?tab=2&customerId=' + customer.id">Посмотреть все заказы</a></p>

    <template #modal-footer="{ close }">
      <b-container>
        <b-row>
          <b-col class="pl-0">
            <b-button @click="edit">
              Редактировать
            </b-button>
          </b-col>
          <b-col class="text-right pr-0">
            <b-button variant="primary" @click="close()">
              Закрыть
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>

import {store} from "@/store"
import CustomerContact from "@/views/CustomerContact";
import {consts} from "@/consts";

export default {
  components: {CustomerContact},

  data() {
    return {
      customer: {},
      global: store.customers,
      types: consts.customers.customerTypesArr
    }
  },

  methods: {
    async onShow() {
      this.customer = await this.$api.get("customers/detailed/" + this.global.selectedCustomer.id);
      this.customer.temporaryBonusDeadlineStr = this.customer.temporaryBonusDeadline ? new Date(this.customer.temporaryBonusDeadline).toLocaleDateString() : null
    },

    edit() {
      this.global.viewCustomerVisible = false
      this.global.showAddCustomer(this.customer)
    }

  }
}
</script>
