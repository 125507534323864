export function isBlank(s) {
    if (s !== undefined && s !== null) {
        return s.toString().trim().length <= 0
    }

    return true
}

export function isPhone(s) {
    s = s.replaceAll(/[^0-9]/ig, "")

    return !isBlank(s) && s.length >= 10
}

export function formatPhone(s) {
    s = s.replaceAll(/[^0-9]/ig, "")

    return !isBlank(s) && s.length >= 11
}

export function isTime(s) {
    if (isBlank(s)) return false
    if (s.indexOf(':') < 0) return false

    const t = s.split(':')
    try {
        if (isBlank(t[0])) return false
        if (t[0].length < 2) return false
        const h = Number(t[0])
        if (h < 0 || h > 23) return false

        if (isBlank(t[1])) return false
        if (t[1].length < 2) return false
        const m = Number(t[1])
        if (m < 0 || m > 59) return false
    } catch (e) {
        return false;
    }

    return true
}

export function formatLocalTime(t) {
    if (!t) return null;
    return padLeft(t[0].toString(), "0", 2) + ":" + padLeft(t[1].toString(), "0", 2)
}

export function padLeft(s, c, l) {
    for (let i = s.length; i < l; i++) s = c + s

    return s
}


export function priceWithBonus(order) {
    return order.total - order.bonusRemoved - order.temporaryBonusRemoved
}
