<template>
  <div>
    <div v-for="(item, itemIndex) in order.items">
      <b-form-row>
        <b-col cols="0" class="mt-1">{{ itemIndex + 1 }}:</b-col>
        <b-col>
          <b-form-group>
            <v-select placeholder="Товар" label="name" :filterable="false" :options="products" v-model="item.product"
                      @search="onProductSearch" @option:selected="onProductSelected(item)">
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <p class="mb-0">Ничего не нашли</p>
                </template>
                <p v-else class="mb-0 text-secondary font-weight-light">Название...</p>
              </template>
              <template v-slot:option="option">
                {{ option.name }} <span class="font-weight-light">{{ option.price }}р.</span>
                <p v-if="option.description" class="mb-0 text-secondary font-weight-light">
                  {{ option.description }}
                </p>
              </template>
              <template v-slot:selected-option="option">
                {{ option.name }}
              </template>
            </v-select>
            <b-form-invalid-feedback :state="validateItemProduct(item)">
              Выберите товар
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <b-form-textarea
                v-model="item.description"
                placeholder="Пожелания клиента к товару"
                rows="2"
                max-rows="4"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Штук:" label-cols="4">
            <b-form-input
                type="number"
                placeholder="Штук"
                v-model="item.amount"
                debounce="250"
                :state="validateItemAmount(item)">
            </b-form-input>
            <b-form-invalid-feedback :state="validateItemAmount(item)">
              Количество товара должно быть больше ноля
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Цена:" label-cols="4">
            <b-form-input
                type="number"
                placeholder="Цена"
                v-model="item.price"
                debounce="250"
              :state="validateItemPrice(item)">
            </b-form-input>
            <b-form-invalid-feedback :state="validateItemPrice(item)">
              Цена должна быть не отрицательная
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="1">
          <b-link v-if="itemIndex > 0" class="h3 mt-1" @click="removeItem(itemIndex)">
            <b-icon-x></b-icon-x>
          </b-link>
        </b-col>
      </b-form-row>
    </div>
    <b-form-row>
      <b-col>
        <p class="text-left">
          <b-button size="sm" @click="addItem">Добавить еще товар</b-button>
        </p>
      </b-col>
      <b-col>
        <p class="text-right">Итого: {{ itemsCost }}р.</p>
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import * as debounce from "lodash.debounce";

export default {
  name: 'OrderItems',
  props: {
    order: Object,
    products: Array,
    submitted: Boolean,
    productSearch: Function
  },
  methods: {
    onProductSearch(search, loading) {
      loading(true);
      this.productSearch(loading, search, this);
    },

    onProductSelected(orderItem) {
      orderItem.price = orderItem.product.price
    },


    removeItem(orderItemIndex) {
      this.order.items.splice(orderItemIndex, 1)
    },

    validateItemAmount(item) {
      return this.submitted ? item.amount > 0 : null
    },

    addItem() {
      this.order.items.push({amount: 1, price: 0, product: {id: 1, name: "Свободная позиция"}})
    },
    validateItemProduct(item) {
      return this.submitted ? item.product != null : null
    },

    validateItemPrice(item) {
      return this.submitted ? item.price >= 0 : null
    },
  },
  computed: {
    itemsCost() {
      return this.order.items.reduce((sum, i) => sum + i.amount * i.price, 0)
    },
  }
}
</script>
<style scoped>
>>> {
  --vs-search-input-placeholder-color: lightgray;
}
</style>