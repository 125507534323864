<template>
  <div>
    <b-container fluid>
      <b-table responsive hover small striped :items="items" :fields="fields">
<!--        <template #head(purchasePrice)="data">-->
<!--          <span class="text-nowrap">{{ data.label }}</span>-->
<!--        </template>-->

        <template #cell(id)="data">
          <p class="mb-0 text-nowrap">
            <b-link @click="edit(data.item)">{{ data.value }}</b-link>
          </p>
        </template>
        <template #cell(customer)="data">
          {{ data.item.customer.name }} {{ data.item.customer.lastName }}
        </template>
<!--        <template #cell(cron)="data">-->
<!--          <b-form-checkbox :checked="data.value" @change="() => onChange(data)"/>&nbsp;-->
<!--        </template>-->
      </b-table>
    </b-container>

    <AddPeriodicOrder :on-save="fetchData"/>
  </div>
</template>

<script>
import AddPeriodicOrder from "@/views/AddPeriodicOrder";
import {store} from "@/store"

export default {
  components: {AddPeriodicOrder},
  data() {
    return {
      filter: "",
      importStarted: false,
      fields: [
        {label: 'Id', key: 'id', sortable: true},
        {label: 'Покупатель', key: 'customer', sortable: true},
        {label: 'Cron', key: 'cron'},
      ],
      items: [],
      customers: [],
      global: store.periodicOrders
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      let dto = await this.$api.get("periodic")
      this.items = dto.periodicOrders
      this.customers = dto.customers
      this.items.map(item => item.customer = this.customers[item.customer.id])
    },

    async onChange(data) {
      console.log(data)
      data.item.hidden = !data.item.hidden
      let newPeriodicOrder = await this.$api.post("periodic", data.item)
    },

    add() {
      this.global.selectedPeriodicOrder = {}
      this.global.addPeriodicOrderVisible = true
    },

    edit(item) {
      this.global.selectedPeriodicOrder = item;
      this.global.addPeriodicOrderVisible = true
    },
  }
}
</script>


