<template>
  <b-modal id="change-delivery" v-model="global.changeCourierVisible" title="Доставка" @show="show" @ok="onSubmit">
    <b-container>
      <b-form-radio-group v-model="newCourier" :options="couriers" button-variant="outline-primary" buttons stacked>
      </b-form-radio-group>
    </b-container>
  </b-modal>
</template>


<script>

import {store} from "@/store"
import {consts} from "@/consts";

export default {

  data() {
    return {
      global: store.orders,
      newCourier: -1,
      couriers: consts.orders.couriers
    }
  },

  methods: {
    show() {
      this.newCourier = this.global.selectedOrder.courier
    },

    async save() {
      if (parseInt(this.newCourier) === 0 || parseInt(this.newCourier) === -1) {
        this.global.selectedOrder.courier = -1
        this.global.selectedOrder.deliveryClaimStatus = 10
      } else {
        this.global.selectedOrder.courier = parseInt(this.newCourier)
        this.global.selectedOrder.deliveryClaimStatus = 19
      }
      await this.$api.post("order", store.orders.selectedOrder)
      this.global.onOrderSaved()
    },

    async onSubmit(event) {
      event.preventDefault()
      store.orders.changeCourierVisible = false
      await this.save()
    }
  },

}
</script>
