<template>
  <b-modal id="fast-add-order" size="md" v-model="global.fastAddOrderVisible"
           title="Добавить заказ"
           @show="onShow" @ok="onSubmit">

    <b-form @submit="onSubmit">
      <b-form-group>
        <b-form-input
            autofocus
            type="text"
            placeholder="Телефон клиента"
            v-model="phone"
            debounce="250"
            :state="validatePhone">
        </b-form-input>
        <b-form-invalid-feedback :state="validatePhone">
          {{
            serverValidationError == null ? "Введите номер телефона в формате +7(123)456-7890" : serverValidationError
          }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <p class="mb-0 text-success" v-if="customer != null">
          Нашли клиента с таким телефоном!
        </p>
        <p class="mb-0" v-if="customer != null">Скидка: {{ customer.discount }}% Бонусы: {{ customer.bonus + customer.temporaryBonus }}
        </p>
        <b-form-input
            type="text"
            placeholder="Имя клиента"
            v-model="name"
            debounce="250"
            :disabled="customer != null"
            :state="validateName">
        </b-form-input>
        <b-form-invalid-feedback :state="validateName">
          Имя не может быть пустым
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group>
        <b-form-input
            type="number"
            placeholder="Общая стоимость заказа"
            v-model="total"
            debounce="250">
          :state="validatePrice">
        </b-form-input>
        <b-form-invalid-feedback :state="validatePrice">
          Стоимость должна быть не отрицательная
        </b-form-invalid-feedback>
        <b-form-group v-if="customer !== null" label="Бонус" label-cols-sm="3" class="mt-3">
          <b-form-radio-group
              v-model="bonusCase"
              :options="bonusCases"
              button-variant="outline-primary"
              size="sm"
              buttons
          ></b-form-radio-group>
        </b-form-group>
      </b-form-group>
      <p> Всего: {{ calcTotal }}р.</p>
    </b-form>
  </b-modal>
</template>

<script>

import {isBlank, isPhone, isTime} from "@/utils";
import {store} from "@/store"

export default {
  data() {
    return {
      phone: null,
      name: null,
      total: null,
      customer: null,
      submitted: false,
      serverValidationError: null,
      bonusCase: -1,
      global: store.orders,
    }
  },

  watch: {
    phone: async function (newVal, oldVal) {
      var customer = await this.$api.get("customers/searchByContact", {contact: this.phone})
      if (customer) {
        this.customer = customer
        this.name = this.customer.name + " " + (this.customer.lastName ? this.customer.lastName : "")
      } else {
        this.customer = null
        this.name = null
      }
    }
  },

  computed: {
    bonusAdded() {
      return Math.round(this.total * 0.05);
    },

    bonusCases() {
      var b = []

      b[0] = {value: -1, text: "Начислить " + this.bonusAdded}

      if (!this.customer || this.customer.bonus + this.customer.temporaryBonus <= 0) {
        b[1] = {value: 1, text: "Списать 0", disabled: true}
      } else {
        b[1] = {value: 1, text: "Списать " + (this.customer.bonus + this.customer.temporaryBonus)}
      }
      return b;
    },

    calcTotal() {
      return Math.max(this.total - (this.bonusCase > 0 ? this.customer.bonus + this.customer.temporaryBonus : 0), 0)
    },
    validatePhone() {
      return this.submitted ? (isPhone(this.phone) && this.serverValidationError == null) : null
    },
    validateName() {
      return this.submitted ? !isBlank(this.name) : null
    },
    validatePrice() {
      return this.submitted ? this.total > 0 : null
    }
  },

  methods: {
    onShow() {
      this.submitted = false
      this.serverValidationError = null
      this.phone = null
      this.name = null
      this.total = null
      this.customer = null
    },

    async onSubmit(event) {
      event.preventDefault()

      this.submitted = true
      this.serverValidationError = null

      if (this.validatePhone && this.validateName && this.validatePrice) {
        try {
          let newOrder = await this.$api.postParams("order/fast", {
            phone: this.phone, name: this.name, total: this.total, removeBonus: this.bonusCase > 0
          })
          this.global.onOrderSaved(newOrder)
          this.global.fastAddOrderVisible = false
          this.$bvToast.toast(`${newOrder.id} добавлен`, {
            title: 'Новый заказ',
            autoHideDelay: 5000,
            appendToast: true,
            solid: true
          })
        } catch (e) {
          this.serverValidationError = e.toString()
        }
      }
    }
  }
}
</script>
