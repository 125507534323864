<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="auto" class="mr-auto pl-1">
          <h2 class="mb-0">Клиенты</h2>
        </b-col>
        <b-col cols="auto" class="pl-0 mt-1 pr-0">
          <b-button-toolbar>
            <b-button-group class="mx-1">
              <b-button @click="addTemporaryBonus()" v-if="user.admin">Добавить временные бонусы</b-button>
            </b-button-group>
            <b-button-group class="mx-1">
              <b-button @click="add()">Новый клиент</b-button>
            </b-button-group>
            <b-button-group class="mx-1">
              <b-button :disabled="!canMerge" @click="merge()">Объеденить</b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="p-1">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Искать" autofocus debounce="250"
                          @update="onFilter"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          <b-form-datepicker v-model="lastDeliveryDate"
                             label-no-date-selected="Последний заказ до"
                             start-weekday="1"
                             @input="onFilter"
                             today-button
                             reset-button
                             close-button>
          </b-form-datepicker>
        </b-col>
      </b-row>
    </b-container>

    <b-table selectable responsive small striped hover :items="items" :fields="fields"
             @row-selected="onCustomerSelected">
      <template #cell(name)="data">
        <b-link @click="edit(data.item)">{{
            data.value + (data.item.lastName ? " " + data.item.lastName : "")
          }}
        </b-link>
      </template>
      <template #cell(contact)="data">
        <CustomerContact :customer="data.item"/>
      </template>
      <template #cell(ordersCount)="data">
        <a :href="'/orders?tab=2&customerId=' + data.item.id">{{ data.value }}</a>
      </template>
    </b-table>
  </div>
</template>

<script>
import {store} from "@/store"
import CustomerContact from "@/views/CustomerContact";

export default {
  components: {CustomerContact},
  data() {
    return {
      fields: [
        {label: 'Имя', key: 'name', sortable: true},
        {label: 'Контакт', key: 'contact', sortable: true},
        {
          label: 'Скидка',
          key: 'discount',
          formatter(value, key, item) {
            return value ? value + "%" : "-";
          },
          sortable: true
        },
        {
          label: 'Бонус',
          key: 'bonus',
          formatter(value, key, item) {
            return value ? value : "-";
          },
          sortable: true
        },
        {label: 'Заказы', key: 'ordersCount', sortable: true},
        {
          label: 'Последний заказ',
          key: 'ordersLastDeliveryDate',
          formatter(value, key, item) {
            return value ? new Date(value).toLocaleDateString() : "";
          },
          sortable: true
        },
        {label: 'Сумма заказов', key: 'ordersTotalSum', sortable: true},
      ],
      items: [],
      filter: "",
      global: store.customers,
      user: store.user,
      selectedCustomers: [],
      lastDeliveryDate: null
    }
  },

  computed: {
    canMerge() {
      return this.selectedCustomers.length >= 2
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      let params = {filter: this.filter};
      if (this.lastDeliveryDate) params.lastDeliveryDate = this.lastDeliveryDate

      this.items = await this.$api.get("customers/searchDetailed",
          params)
    },

    onFilter() {
      this.fetchData()
    },

    onCustomerSelected(items) {
      this.selectedCustomers = items
    },

    async merge() {
      let res = await this.$bvModal.msgBoxConfirm(
          `Все заказы будут перемещены на ${this.selectedCustomers[0].name}, и остальные клиенты будут удалены.`)
      if (res) {
        await this.$api.postJson("customers/merge/" + this.selectedCustomers[0].id,
            this.selectedCustomers.map(c => c.id));
        await this.fetchData()
      }
    },

    add() {
      this.global.showAddCustomer(null, this.fetchData)
    },

    edit(item) {
      this.global.showAddCustomer(item, this.fetchData)
    },
    addTemporaryBonus() {
      this.global.showAddTemporaryBonus(this.fetchData)
    },

  }
}
</script>


