import { render, staticRenderFns } from "./AddTemporaryBonus.vue?vue&type=template&id=bb3a8954&v-if=global.addTemporaryBonusVisible"
import script from "./AddTemporaryBonus.vue?vue&type=script&lang=js"
export * from "./AddTemporaryBonus.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports